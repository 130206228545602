<template>
  <div
    class="content"
    style="padding:20px;background-color:#141735;color:#fff;"
  >
    <div>
      <el-form
        :inline="true"
        :model="formInline"
        class="demo-form-inline"
        size="mini"
      >
        <el-form-item>
          <el-select
            v-model="formInline.collectType"
            placeholder="请选择"
            size="small"
            style="width:120px;margin-right:10px"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select
            v-model="formInline.year"
            placeholder="请选择"
            size="small"
            @change="yearChange"
            style="width:90px;margin-right:10px"
          >
            <el-option
              v-for="item in options1"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select
            v-model="formInline.month"
            placeholder="请选择"
            size="small"
            style="width:90px;margin-right:10px"
          >
            <el-option
              v-for="item in options2"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitForm">查询</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="tianzhang">
      <div class="title">{{ typeinfo }}电子台账</div>
      <div
        style="border:1px solid #3D4283;border-radius: 5px;overflow: hidden;margin-bottom:40px;background: #1e2146;"
      >
        <table>
          <tr class="b-t">
            <td class="td-bg">商户名称</td>
            <td style="text-align:left">{{ info.name }}</td>
            <td class="td-bg">营业执照号</td>
            <td class="b-r">{{ info.bizLicenseNum }}</td>
          </tr>
          <tr>
            <td class="td-bg">联系人</td>
            <td>{{ info.linkman }}</td>
            <td class="td-bg">许可证号</td>
            <td class="b-r">{{ info.licenseNum }}</td>
          </tr>
          <tr>
            <td class="td-bg">联系电话</td>
            <td>{{ info.contactTel }}</td>
            <td class="td-bg">商户地址</td>
            <td class="b-r" style="text-align:left">
              {{ info.detailAddress }}
            </td>
          </tr>
        </table>
      </div>

      <div class="title2">（一）{{ dateinfo }} {{ typeinfo }}台账摘要</div>
      <div
        style="border:1px solid #3D4283;border-radius: 5px;overflow: hidden;margin-bottom:40px;background: #1e2146;"
      >
        <table style="text-align: left;">
          <tr class="b-t">
            <td class="b-r">
              {{ dateinfo }}总计废弃油脂：<span style="color:#FFB82F">{{
                info.allWeight
              }}</span
              >kg
            </td>
          </tr>
          <tr>
            <td class="b-r">
              {{ dateinfo }}总废弃油脂次数：<span style="color:#FFB82F">{{
                info.allCollectCount
              }}</span>
            </td>
          </tr>
        </table>
      </div>

      <div class="title2">（二）{{ dateinfo }} {{ typeinfo }}台账明细</div>
      <div
        style="border:1px solid #3D4283;border-radius: 5px;overflow: hidden;"
      >
        <el-table :data="tableData" style="width: 100%;">
          <el-table-column prop="collectDate" label="收运日期">
          </el-table-column>
          <el-table-column prop="collectWeight" label="收运重量/kg">
          </el-table-column>
          <el-table-column prop="collectorEmployee" label="收运人员">
          </el-table-column>
          <el-table-column prop="collectorName" label="收运单位">
          </el-table-column>
        </el-table>
      </div>

      <div class="posit">
        <div class="time">{{ info.generateDate }}</div>
        <div style="margin:40px 0 20px 0">
          <el-button type="success">保存图片</el-button>
          <el-button type="primary">下载PDF</el-button>
        </div>
        <img src="@/assets/images/taizhang.png" class="img" />
        <img src="@/assets/images/qrcode.png" class="qrcode" />
      </div>
    </div>
  </div>
</template>

<script>
import { ledger } from "@/api/taizhang";
var date = new Date();
export default {
  data() {
    return {
      options: [
        {
          value: 1,
          label: "废弃油脂"
        },
        {
          value: 2,
          label: "废弃固渣"
        }
      ],
      options1: [],
      options2: [],
      formInline: {
        collectType: 1,
        year: date.getFullYear(),
        month: date.getMonth() + 1
      },
      id: "",
      info: {},
      dateinfo: "",
      typeinfo: "",
      tableData: []
    };
  },
  created() {
    this.getDate();
  },
  mounted() {
    this.id = this.$route.query.id;
    this.getledger(this.id, this.formInline);
  },
  methods: {
    submitForm() {
      // console.log(this.formInline);
      this.getledger(this.id, this.formInline);
    },
    getledger(merchantCode, query) {
      this.typeinfo = query.collectType == 1 ? "废弃油脂" : "废弃固渣";
      this.dateinfo = query.year + "年" + query.month + "月";
      // console.log(query);
      ledger(merchantCode, query).then(res => {
        this.info = res.data;
        this.tableData = res.data.detail;
        console.log(res);
      });
    },
    getDate() {
      var nowYears = date.getFullYear();
      var nowMonths = date.getMonth() + 1;
      var Years = nowYears - 2019;
      var Months = nowMonths - 1;
      var arrYear = [];
      var arrMonth = [];
      for (let i = 0; i <= Years; i++) {
        arrYear.push(nowYears--);
      }
      for (let j = 0; j <= Months; j++) {
        arrMonth.push(j + 1);
      }
      const data = arrYear.map(el => {
        return { value: el, label: el + "年" };
      });
      this.options1 = data;
      const data2 = arrMonth.map(item => {
        return { value: item, label: item + "月" };
      });
      this.options2 = data2;
    },
    yearChange(e) {
      if (e != date.getFullYear()) {
        this.options2 = [
          {
            value: 1,
            label: "1月"
          },
          {
            value: 2,
            label: "2月"
          },
          {
            value: 3,
            label: "3月"
          },
          {
            value: 4,
            label: "4月"
          },
          {
            value: 5,
            label: "5月"
          },
          {
            value: 6,
            label: "6月"
          },
          {
            value: 7,
            label: "7月"
          },
          {
            value: 8,
            label: "8月"
          },
          {
            value: 9,
            label: "9月"
          },
          {
            value: 10,
            label: "10月"
          },
          {
            value: 11,
            label: "11月"
          },
          {
            value: 12,
            label: "12月"
          }
        ];
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.tianzhang {
  width: 80%;
  text-align: center;
  margin: 0 auto;
  margin-top: 50px;
}
.title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 50px;
}
.title2 {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 40px;
}
table {
  border-spacing: 0;
  width: 100%;
  height: 100%;
  text-align: center;
}
td {
  border-top: 1px solid #3d4283;
  border-right: 1px solid #3d4283;
  border-bottom: 0;
  border-left: 0;
  padding: 20px;
}
table tr td:nth-child(odd) {
  width: 15%;
}
table tr td:nth-child(even) {
  width: 35%;
}
.td-bg {
  background: #2e3367;
}
.b-r {
  border-right: 0;
}
.b-t {
  td {
    border-top: 0;
  }
}
.time {
  text-align: right;
  margin: 40px;
}
.posit {
  position: relative;
}
.img {
  position: absolute;
  transform: rotate(15deg);
  bottom: 20px;
  right: 10px;
}
.qrcode {
  position: absolute;
  bottom: 0;
  left: 80px;
}
.content {
  background-image: url("~@/assets/images/shuiyin.png");
}
</style>
<style lang="scss">
.tianzhang {
  .el-table {
    color: #fff;
    background-color: #1e2146;
  }
  .el-table th,
  .el-table tr {
    background-color: #1e2146;
  }
  .el-table th {
    background-color: #12142f;
  }
}
</style>

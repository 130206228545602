import request from "@/utils/request";

// 地图商家信息窗体详情
export function detailInfo(merchantCode) {
  return request({
    url: "/bi/merchant/detail/info/" + merchantCode,
    method: "GET"
  });
}

// 油 / 渣收运概览
export function overView(merchantCode, query) {
  return request({
    url: "/bi/merchant/detail/collect/overview/" + merchantCode,
    method: "GET",
    params: query
  });
}

// 收运详情查询条件
export function condition(merchantCode) {
  return request({
    url: "/bi/merchant/detail/collect/list/condition/" + merchantCode,
    method: "GET"
  });
}

// 收运详情
export function collectList(merchantCode, query) {
  return request({
    url: "/bi/merchant/detail/collect/list/" + merchantCode,
    method: "GET",
    params: query
  });
}

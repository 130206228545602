import request from "@/utils/request";

// 台账详情
export function ledger(merchantCode, query) {
  return request({
    url: "/bi/merchant/detail/ledger/" + merchantCode,
    method: "GET",
    params: query
  });
}

<template>
  <div id="details">
    <div class="details-content">
      <div class="flex flex-center" style="margin-bottom: 30px">
        <img
          src="@/assets/images/merchants_details/return.png"
          class="return-img"
          @click="returnIndex"
        />
        <div class="bgimg">
          <span style="font-weight: bold;">{{ info.shortName }}</span> （{{
            info.name
          }}）
        </div>
        <el-dialog
          title="电子台账"
          :visible.sync="dialogVisible"
          width="60%;"
          top="8vh"
          :close-on-click-modal="false"
        >
          <div style="height:80vh;overflow-y:auto;">
            <TaiZhang />
          </div>
        </el-dialog>
      </div>
      <div class="flex flex-center">
        <div class="bg height280" style="width: 50%; margin-right: 20px">
          <table>
            <tr class="b-t">
              <td class="td-bg">经营状态</td>
              <td>{{ info.operatState }}</td>
              <td class="td-bg">营业执照号</td>
              <td>{{ info.bizLicenseNum }}</td>
              <td class="td-bg">商家类型</td>
              <td class="b-r">{{ info.merchantType }}</td>
            </tr>
            <tr>
              <td class="td-bg">经营类型</td>
              <td>{{ info.bizType }}</td>
              <td class="td-bg">许可证号</td>
              <td>{{ info.licenseNum }}</td>
              <td class="td-bg">入驻日期</td>
              <td class="b-r">{{ info.settledTime }}</td>
            </tr>
            <tr>
              <td class="td-bg">联系方式</td>
              <td>{{ info.linkman }} {{ info.contactTel }}</td>
              <td class="td-bg">店面面积</td>
              <td>{{ info.storesSize == null ? "-" : info.storesSize }}</td>
              <td rowspan="2" class="td-bg">分离设备</td>
              <td rowspan="2" class="b-r">
                <div v-if="info.equipment != 0">
                  <div
                    v-for="item in info.equipment"
                    :key="item.id"
                    style="display:flex;align-items: center;"
                  >
                    {{ item.equipmentName }}
                    <span
                      :style="{
                        color: item.equipmentStatus == 1 ? '#67C23A' : '#F56C6C'
                      }"
                      style="margin:0 10px"
                      >{{ item.equipmentStatus == 1 ? "在线" : "离线" }}</span
                    >
                    <el-tooltip
                      class="tooltip"
                      effect="dark"
                      :content="item.statusTime"
                      placement="right"
                    >
                      <img
                        src="@/assets/images/merchants_details/time_icon.png"
                        alt=""
                      />
                    </el-tooltip>
                  </div>
                </div>
                <div v-else style="text-align:center">-</div>
              </td>
            </tr>
            <tr>
              <td class="td-bg">收运单位</td>
              <td colspan="3">
                <div
                  v-if="info.oilCollectorName != null"
                  style="display:flex;align-items: center;margin-bottom:5px"
                >
                  <img
                    src="@/assets/images/merchants_details/you.png"
                    style="margin-right:5px"
                  />{{ info.oilCollectorName }}
                </div>
                <div
                  v-if="info.solidResidueCollectorName != null"
                  style="display:flex;align-items: center;"
                >
                  <img
                    src="@/assets/images/merchants_details/zha.png"
                    style="margin-right:5px"
                  />{{ info.solidResidueCollectorName }}
                </div>
              </td>
            </tr>
            <tr>
              <td class="td-bg">详细地址</td>
              <td colspan="5" class="b-r">{{ info.detailAddress }}</td>
            </tr>
          </table>
        </div>
        <div
          class="bg height280 flex flex-center"
          style="width: 50%; padding: 10px"
        >
          <div class="certificate">
            <el-image
              :src="apiUrl + info.bizLicensePic"
              :preview-src-list="[apiUrl + info.bizLicensePic]"
            >
              <div slot="error" class="image-slot">
                <img
                  src="@/assets/images/merchants_details/default.png"
                  alt=""
                />
              </div>
            </el-image>
            <div class="text">营业执照</div>
          </div>
          <div class="certificate">
            <el-image
              :src="apiUrl + info.licensePic"
              :preview-src-list="[apiUrl + info.licensePic]"
            >
              <div slot="error" class="image-slot">
                <img
                  src="@/assets/images/merchants_details/default.png"
                  alt=""
                />
              </div>
            </el-image>
            <div class="text">许可证</div>
          </div>
          <div class="certificate">
            <el-image :src="idCardPic" :preview-src-list="srcList1">
              <div slot="error" class="image-slot">
                <img
                  src="@/assets/images/merchants_details/default.png"
                  alt=""
                />
              </div>
            </el-image>
            <div class="text">法人身份证</div>
          </div>
          <div class="certificate">
            <el-image
              :src="apiUrl + info.doorHeadPic"
              :preview-src-list="[apiUrl + info.doorHeadPic]"
            >
              <div slot="error" class="image-slot">
                <img
                  src="@/assets/images/merchants_details/default.png"
                  alt=""
                />
              </div>
            </el-image>
            <div class="text">商家门头</div>
          </div>
          <div class="certificate">
            <el-image :src="contractPic" :preview-src-list="srcList">
              <div slot="error" class="image-slot">
                <img
                  src="@/assets/images/merchants_details/default.png"
                  alt=""
                />
              </div>
            </el-image>
            <div class="text">合同</div>
          </div>
        </div>
      </div>
      <div class="flex flex-center margin-b-20">
        日期范围<el-date-picker
          v-model="timeValue"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          size="small"
          value-format="yyyy-MM-dd"
          @change="timeChange"
        >
        </el-date-picker>
        <div
          class="btn-time"
          :class="colorActive == 1 ? '' : 'btn-time-active'"
          @click="monthClick(1)"
        >
          近一月
        </div>
        <div
          class="btn-time"
          :class="colorActive == 3 ? '' : 'btn-time-active'"
          @click="monthClick(3)"
        >
          近三月
        </div>
        <div
          class="btn-time"
          :class="colorActive == 12 ? '' : 'btn-time-active'"
          @click="monthClick(12)"
        >
          近一年
        </div>
      </div>
      <div class="bg padding20">
        <div class="title-item">油 / 渣收运概览</div>

        <div id="chart-line"></div>
        <div class="flex flex-center" style="height: 300px;color:#30D8FC">
          <div class="flex flex-center" style="flex: 1">
            <div
              style="display: flex;flex-direction: column;padding-left:10%;width:40%"
            >
              <div class="flex flex-center">
                <img
                  class="icon-img"
                  src="@/assets/images/merchants_details/icon1.png"
                  alt=""
                />
                <div>
                  <div>{{ oil.oilAllWeight }}kg</div>
                  <div style="margin-top:5px">油脂总量</div>
                </div>
              </div>
              <div class="flex flex-center" style="margin:20px 0">
                <img
                  class="icon-img"
                  src="@/assets/images/merchants_details/icon2.png"
                  alt=""
                />
                <div>
                  <div>{{ oil.merchantOilAvgWeight }}kg/天</div>
                  <div style="margin-top:5px">油脂平均量</div>
                </div>
              </div>
              <div class="flex flex-center">
                <img
                  class="icon-img"
                  src="@/assets/images/merchants_details/icon3.png"
                  alt=""
                />
                <div>
                  <div>{{ oil.oilAvgWeight }}kg/天</div>
                  <div style="margin-top:5px">
                    同业平均量<el-tooltip
                      class="tooltip"
                      effect="dark"
                      content="同一种餐饮类型的平均量"
                      placement="right"
                    >
                      <i class="el-icon-question" style="margin-left:5px"></i>
                    </el-tooltip>
                  </div>
                </div>
              </div>
            </div>
            <div style="width:60%">
              <div id="details-chart-bar"></div>
            </div>
          </div>
          <el-divider direction="vertical"></el-divider>
          <div class="flex flex-center" style="flex: 1;">
            <div
              style="display: flex;flex-direction: column;padding-left:10%;width:40%"
            >
              <div class="flex flex-center">
                <img
                  class="icon-img"
                  src="@/assets/images/merchants_details/icon4.png"
                  alt=""
                />
                <div>
                  <div>{{ solid.solidResidueAllWeight }}kg</div>
                  <div style="margin-top:5px">固渣总量</div>
                </div>
              </div>
              <div class="flex flex-center" style="margin:20px 0">
                <img
                  class="icon-img"
                  src="@/assets/images/merchants_details/icon5.png"
                  alt=""
                />
                <div>
                  <div>{{ solid.merchantSolidResidueAvgWeight }}kg/天</div>
                  <div style="margin-top:5px">固渣平均量</div>
                </div>
              </div>
              <div class="flex flex-center">
                <img
                  class="icon-img"
                  src="@/assets/images/merchants_details/icon6.png"
                  alt=""
                />
                <div>
                  <div>{{ solid.solidResidueAvgWeight }}kg/天</div>
                  <div style="margin-top:5px">
                    同业平均量<el-tooltip
                      class="tooltip"
                      effect="dark"
                      content="同一种餐饮类型的平均量"
                      placement="right"
                    >
                      <i class="el-icon-question" style="margin-left:5px"></i>
                    </el-tooltip>
                  </div>
                </div>
              </div>
            </div>
            <div style="width:60%">
              <div id="details-chart-bar2"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="bg padding20">
        <div class="title-item">油 / 渣收运详情</div>
        <!-- <div v-if="tableData1.list.length !== 0"> -->
        <div class="form-search flex-v-center">
          <el-form
            :inline="true"
            :model="formSelect"
            class="demo-form-inline"
            size="small"
          >
            <el-form-item label="收运类型">
              <el-select
                v-model="formSelect.type"
                clearable
                placeholder="请选择"
              >
                <el-option label="油脂" value="1"></el-option>
                <el-option label="固渣" value="2"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="收运单位">
              <el-select
                v-model="formSelect.unit"
                clearable
                placeholder="请选择"
              >
                <el-option
                  v-for="item in options1"
                  :key="item.collectorKey"
                  :label="item.collectorName"
                  :value="item.collectorKey"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="收运人员">
              <el-select
                v-model="formSelect.user"
                clearable
                placeholder="请选择"
              >
                <el-option
                  v-for="item in options2"
                  :key="item.employeeId"
                  :label="item.employeeName"
                  :value="item.employeeId"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="收运状态">
              <el-select
                v-model="formSelect.status"
                clearable
                placeholder="请选择"
              >
                <el-option label="运输中" value="1"></el-option>
                <el-option label="已完成" value="2"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="seach">查询</el-button>
            </el-form-item>
          </el-form>
          <div>导出报表</div>
        </div>
        <div>
          <el-table :data="tableData1.list" style="width: 100%">
            <el-table-column prop="date" label="收运日期"> </el-table-column>
            <el-table-column prop="collectType" label="收运类型">
            </el-table-column>
            <el-table-column prop="weight" label="收运重量/kg">
            </el-table-column>
            <el-table-column prop="collectorName" label="收运单位">
            </el-table-column>
            <el-table-column prop="collectorEmployee" label="收运人员">
            </el-table-column>
            <el-table-column prop="collectorEmployeePhone" label="收运人员电话">
            </el-table-column>
            <el-table-column prop="collectStatus" label="收运状态">
            </el-table-column>
          </el-table>
          <div class="flex-v-center" style="margin-top: 10px">
            <el-button type="primary" size="small" @click="dialogVisible = true"
              >查看台账</el-button
            >
            <el-pagination
              background
              layout="prev, pager, next"
              :total="tableData1.total"
              :current-page="tableData1.currentpage"
              :page-size="tableData1.pagesize"
              @current-change="handleCurrentChange1"
              prev-text="上一页"
              next-text="下一页"
              hide-on-single-page
            >
            </el-pagination>
          </div>
        </div>
        <!-- </div>
        <div v-else class="empty">
          <img src="@/assets/images/merchants_details/empty.png" alt="" />
          <div>暂无数据</div>
        </div> -->
      </div>
      <div class="bg padding20">
        <div class="title-item">油脂采购详情</div>
        <div v-if="tableData2.length !== 0">
          <div class="form-search flex-v-center">
            <div></div>
            <div>导出报表</div>
          </div>
          <div>
            <el-table :data="tableData2" style="width: 100%"> </el-table>
            <div class="flex-v-center" style="margin-top: 10px">
              <div></div>
              <el-pagination
                background
                layout="prev, pager, next"
                :total="6"
                :page-size="5"
                prev-text="上一页"
                next-text="下一页"
                hide-on-single-page
              >
              </el-pagination>
            </div>
          </div>
        </div>
        <div v-else class="empty">
          <img src="@/assets/images/merchants_details/empty.png" alt="" />
          <div>暂无数据</div>
        </div>
      </div>
      <!-- <div class="bg padding20">
        <div class="title-item">智能预警信息</div>
        <div v-if="tableData.length !== 0">
          <div class="form-search flex-v-center">
            <div></div>
            <div>导出报表</div>
          </div>
          <div>
            <el-table :data="tableData" style="width: 100%">
              <el-table-column prop="date" label="收运日期"> </el-table-column>
              <el-table-column prop="name" label="收运类型"> </el-table-column>
              <el-table-column prop="address" label="收运重量/kg">
              </el-table-column>
              <el-table-column prop="address" label="收运单位">
              </el-table-column>
              <el-table-column prop="address" label="收运人员">
              </el-table-column>
              <el-table-column prop="address" label="收运人员电话">
              </el-table-column>
              <el-table-column prop="address" label="收运状态">
              </el-table-column>
            </el-table>
            <div class="flex-v-center" style="margin-top: 10px">
              <div></div>
              <el-pagination
                background
                layout="prev, pager, next"
                :total="6"
                :page-size="5"
                prev-text="上一页"
                next-text="下一页"
                hide-on-single-page
              >
              </el-pagination>
            </div>
          </div>
        </div>
        <div v-else class="empty">
          <img src="@/assets/images/merchants_details/empty.png" alt="" />
          <div>暂无数据</div>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import "echarts-liquidfill";
import TaiZhang from "@/views/TaiZhang/taizhang";
import { detailInfo, overView, condition, collectList } from "@/api/details";
export default {
  components: { TaiZhang },
  data() {
    return {
      apiUrl: process.env.VUE_APP_BASE_API,
      // 商户id
      id: "",
      // 商户详细信息
      info: {},
      // 身份证
      contractPic: "",
      srcList: [],
      // 合同
      idCardPic: "",
      srcList1: [],
      // 时间选择
      timeValue: "",
      // 查询参数
      query: {
        offsetMonth: 1,
        beginDate: "",
        endDate: ""
      },
      colorActive: 1,
      // 油、渣收运概览
      chartData: {
        xData: [],
        yData1: [],
        yData2: []
      },
      oil: {
        merchantOilAvgWeight: "0.00",
        oilAllWeight: "0.00",
        oilAvgWeight: "0.00"
      },
      solid: {
        solidResidueAllWeight: "0.00",
        solidResidueAvgWeight: "0.00",
        merchantSolidResidueAvgWeight: "0.00"
      },
      // 油渣收运详情
      formSelect: {
        type: "",
        status: "",
        unit: "",
        user: ""
      },
      options1: [],
      options2: [],
      // 收运详情
      tableData1: {
        total: 0,
        currentpage: 1,
        pagesize: 5,
        list: []
      },
      // 采购详情
      tableData2: [],
      // tableData: [
      //   {
      //     date: "2016-05-02",
      //     name: "王小虎",
      //     address: "上海市普陀区金沙江路 1518 弄"
      //   },
      //   {
      //     date: "2016-05-04",
      //     name: "王小虎",
      //     address: "上海市普陀区金沙江路 1517 弄"
      //   },
      //   {
      //     date: "2016-05-01",
      //     name: "王小虎",
      //     address: "上海市普陀区金沙江路 1519 弄"
      //   },
      //   {
      //     date: "2016-05-03",
      //     name: "王小虎",
      //     address: "上海市普陀区金沙江路 1516 弄"
      //   },
      //   {
      //     date: "2016-05-01",
      //     name: "王小虎",
      //     address: "上海市普陀区金沙江路 1519 弄"
      //   }
      // ],
      tableData3: [],
      dialogVisible: false
    };
  },
  created() {
    this.id = this.$route.query.id;
    // this.id = "16113013081302cdaqi";
  },
  mounted() {
    // 获取商户信息
    this.getShopinfo(this.id);
    this.monthClick(1);
    // 智能预警 （还没写）
  },
  methods: {
    returnIndex() {
      this.$router.go(-1);
    },
    getShopinfo(merchantCode) {
      this.srcList = [];
      this.srcList1 = [];
      detailInfo(merchantCode).then(res => {
        // console.log(res);

        this.info = res.data;
        res.data.contractPic.map(item => {
          this.srcList.push(this.apiUrl + item);
        });
        res.data.idCardPic.map(item => {
          this.srcList1.push(this.apiUrl + item);
        });

        this.contractPic = this.apiUrl + res.data.contractPic[0];
        this.idCardPic = this.apiUrl + res.data.idCardPic[0];
      });
    },
    timeChange(e) {
      // console.log(e);
      if (e != null) {
        this.query = {
          beginDate: e[0],
          endDate: e[1]
        };
        this.colorActive = 0;
        this.getOverview(this.id, this.query);
        this.getCondition(this.id);
        this.getCollectList(this.id, this.query);
      } else {
        this.colorActive = 1;
        this.monthClick(1);
      }
    },
    monthClick(data) {
      // console.log(data);

      this.colorActive = data;
      this.timeValue = "";
      this.query = {
        offsetMonth: data
      };
      // 油、渣收运概览
      this.getOverview(this.id, this.query);
      // //详情查询条件
      // this.getCondition(this.id);
      // // 收运详情
      // this.getCollectList(this.id, this.query);
    },
    getOverview(merchantCode, query) {
      // console.log(query);
      overView(merchantCode, query).then(res => {
        // console.log(res);

        this.oil = {
          merchantOilAvgWeight: res.data.merchantOilAvgWeight,
          oilAllWeight: res.data.oilAllWeight,
          oilAvgWeight: res.data.oilAvgWeight
        };
        let oilData;
        if (res.data.oilAvgWeight != 0) {
          oilData = res.data.merchantOilAvgWeight / res.data.oilAvgWeight;
        } else {
          oilData = 0;
        }
        this.detailsChartBar(oilData);
        this.solid = {
          solidResidueAllWeight: res.data.solidResidueAllWeight,
          solidResidueAvgWeight: res.data.solidResidueAvgWeight,
          merchantSolidResidueAvgWeight: res.data.merchantSolidResidueAvgWeight
        };
        let solidData;
        if (res.data.solidResidueAvgWeight != 0) {
          solidData =
            res.data.merchantSolidResidueAvgWeight /
            res.data.solidResidueAvgWeight;
        } else {
          solidData = 0;
        }
        this.detailsChartBar2(solidData);
        this.chartData = {
          xData: [],
          yData1: [],
          yData2: []
        };
        res.data.overviewList.map(item => {
          this.chartData.xData.push(item.date);
          this.chartData.yData1.push(item.oilWeight);
          this.chartData.yData2.push(item.solidResidueWeight);
        });
        this.chartLine();
      });
    },
    chartLine() {
      let chartLine = this.$echarts.init(document.getElementById("chart-line"));
      //echarts折线图
      let option = {
        // backgroundColor: "#fff",
        grid: {
          top: "10%",
          left: "0%",
          right: "1%",
          bottom: "0%",
          containLabel: true
        },
        legend: {
          show: true,
          icon: "circle",
          top: "10%",
          textStyle: {
            color: "#fff"
          }
        },
        tooltip: {
          trigger: "axis"
        },
        xAxis: [
          {
            type: "category",
            data: this.chartData.xData,
            axisLine: {
              lineStyle: {
                color: "#ddd"
              }
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              textStyle: {
                color: "#fff"
              }
            },
            boundaryGap: false
          }
        ],
        yAxis: [
          {
            type: "value",
            axisTick: {
              show: false
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: "#ddd"
              }
            },
            axisLabel: {
              textStyle: {
                color: "#c56790"
              }
            },
            splitLine: {
              show: false
            }
          }
        ],
        series: [
          {
            name: "油脂",
            type: "line",
            data: this.chartData.yData1,
            symbolSize: 6,
            symbol: "circle",
            smooth: true,
            lineStyle: {
              color: "#fe9a8b"
            },
            itemStyle: {
              normal: {
                color: "#fe9a8b",
                borderColor: "#fe9a8b"
              }
            },
            areaStyle: {
              color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "#fe9a8bb3"
                },
                {
                  offset: 1,
                  color: "#fe9a8b03"
                }
              ])
            },
            emphasis: {
              itemStyle: {
                color: {
                  type: "radial",
                  x: 0.5,
                  y: 0.5,
                  r: 0.5,
                  colorStops: [
                    {
                      offset: 0,
                      color: "#fe9a8b"
                    },
                    {
                      offset: 0.4,
                      color: "#fe9a8b"
                    },
                    {
                      offset: 0.5,
                      color: "#fff"
                    },
                    {
                      offset: 0.7,
                      color: "#fff"
                    },
                    {
                      offset: 0.8,
                      color: "#fff"
                    },
                    {
                      offset: 1,
                      color: "#fff"
                    }
                  ]
                },
                borderColor: "#fe9a8b",
                borderWidth: 2
              }
            }
          },
          {
            name: "固渣",
            type: "line",
            data: this.chartData.yData2,
            symbolSize: 6,
            symbol: "circle",
            smooth: true,
            lineStyle: {
              color: "#9E87FF"
            },
            itemStyle: {
              normal: {
                color: "#9E87FF",
                borderColor: "#9E87FF"
              }
            },
            areaStyle: {
              color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "#9E87FFb3"
                },
                {
                  offset: 1,
                  color: "#9E87FF03"
                }
              ])
            },
            emphasis: {
              itemStyle: {
                color: {
                  type: "radial",
                  x: 0.5,
                  y: 0.5,
                  r: 0.5,
                  colorStops: [
                    {
                      offset: 0,
                      color: "#9E87FF"
                    },
                    {
                      offset: 0.4,
                      color: "#9E87FF"
                    },
                    {
                      offset: 0.5,
                      color: "#fff"
                    },
                    {
                      offset: 0.7,
                      color: "#fff"
                    },
                    {
                      offset: 0.8,
                      color: "#fff"
                    },
                    {
                      offset: 1,
                      color: "#fff"
                    }
                  ]
                },
                borderColor: "#9E87FF",
                borderWidth: 2
              }
            }
          }
        ]
      };
      // 绘制图表
      chartLine.setOption(option);

      window.addEventListener("resize", () => {
        chartLine.resize();
      });
    },
    detailsChartBar(data) {
      let detailsChart = this.$echarts.init(
        document.getElementById("details-chart-bar")
      );
      let option = {
        // backgroundColor: "#050038",
        series: [
          {
            type: "liquidFill",
            radius: "60%",
            center: ["50%", "50%"],
            data: [data, data, data], // data个数代表波浪数
            backgroundStyle: {
              borderWidth: 1,
              color: "rgba(54, 59, 110, 0.5)"
            },
            label: {
              normal: {
                formatter: (data * 100).toFixed(2) + "%",
                textStyle: {
                  fontSize: 22
                }
              }
            },
            outline: {
              show: false
            }
          },
          {
            type: "pie",
            center: ["50%", "50%"],
            radius: ["65%", "68%"],
            hoverAnimation: false,
            data: [
              {
                name: "",
                value: 100,
                labelLine: {
                  show: false
                },
                itemStyle: {
                  color: "#5886f0"
                },
                emphasis: {
                  labelLine: {
                    show: false
                  },
                  itemStyle: {
                    color: "#5886f0"
                  }
                }
              },
              {
                //画中间的图标
                name: "",
                value: 5,
                labelLine: {
                  show: false
                },
                itemStyle: {
                  color: "#ffffff",
                  normal: {
                    color: "#5886f0",
                    borderColor: "#5886f0",
                    borderWidth: 5,
                    borderRadius: "100%"
                  }
                },
                label: {
                  borderRadius: "100%"
                },
                emphasis: {
                  labelLine: {
                    show: false
                  },
                  itemStyle: {
                    color: "#5886f0"
                  }
                }
              },
              {
                // 解决圆点过大后续部分显示明显的问题
                name: "",
                value: 0.5,
                labelLine: {
                  show: false
                },
                itemStyle: {
                  color: "#5886f0"
                },
                emphasis: {
                  labelLine: {
                    show: false
                  },
                  itemStyle: {
                    color: "#5886f0"
                  }
                }
              },
              {
                //画剩余的刻度圆环
                name: "",
                value: 10,
                itemStyle: {
                  color: "rgba(54, 59, 110, 0.29)"
                },
                label: {
                  show: false
                },
                labelLine: {
                  show: false
                }
              }
            ]
          }
        ]
      };
      // 绘制图表
      detailsChart.setOption(option);

      window.addEventListener("resize", () => {
        detailsChart.resize();
      });
    },
    detailsChartBar2(data) {
      let detailsChart = this.$echarts.init(
        document.getElementById("details-chart-bar2")
      );
      let option = {
        // backgroundColor: "#050038",
        series: [
          {
            type: "liquidFill",
            radius: "60%",
            center: ["50%", "50%"],
            data: [data, data, data], // data个数代表波浪数
            backgroundStyle: {
              borderWidth: 1,
              color: "rgba(54, 59, 110, 0.5)"
            },
            label: {
              normal: {
                formatter: (data * 100).toFixed(2) + "%",
                textStyle: {
                  fontSize: 22
                }
              }
            },
            outline: {
              show: false
            }
          },
          {
            type: "pie",
            center: ["50%", "50%"],
            radius: ["65%", "68%"],
            hoverAnimation: false,
            data: [
              {
                name: "",
                value: 100,
                labelLine: {
                  show: false
                },
                itemStyle: {
                  color: "#5886f0"
                },
                emphasis: {
                  labelLine: {
                    show: false
                  },
                  itemStyle: {
                    color: "#5886f0"
                  }
                }
              },
              {
                //画中间的图标
                name: "",
                value: 5,
                labelLine: {
                  show: false
                },
                itemStyle: {
                  color: "#ffffff",
                  normal: {
                    color: "#5886f0",
                    borderColor: "#5886f0",
                    borderWidth: 5,
                    borderRadius: "100%"
                  }
                },
                label: {
                  borderRadius: "100%"
                },
                emphasis: {
                  labelLine: {
                    show: false
                  },
                  itemStyle: {
                    color: "#5886f0"
                  }
                }
              },
              {
                // 解决圆点过大后续部分显示明显的问题
                name: "",
                value: 0.5,
                labelLine: {
                  show: false
                },
                itemStyle: {
                  color: "#5886f0"
                },
                emphasis: {
                  labelLine: {
                    show: false
                  },
                  itemStyle: {
                    color: "#5886f0"
                  }
                }
              },
              {
                //画剩余的刻度圆环
                name: "",
                value: 10,
                itemStyle: {
                  color: "rgba(54, 59, 110, 0.29)"
                },
                label: {
                  show: false
                },
                labelLine: {
                  show: false
                }
              }
            ]
          }
        ]
      };
      // 绘制图表
      detailsChart.setOption(option);

      window.addEventListener("resize", () => {
        detailsChart.resize();
      });
    },
    getCondition(merchantCode) {
      condition(merchantCode).then(res => {
        // console.log(res);
        this.options1 = res.data.collector;
        this.options2 = res.data.collectorEmployee;
      });
    },
    getCollectList(merchantCode, query) {
      // console.log(this.formSelect);
      query.collectType = this.formSelect.type;
      query.collectorCode = this.formSelect.unit;
      query.collectorEmployeeId = this.formSelect.user;
      query.collectStatus = this.formSelect.status;
      query.pageSize = this.tableData1.pagesize;
      query.pageNum = this.tableData1.currentpage;
      query.isAsc = "asc";
      // console.log(query);
      collectList(merchantCode, query).then(res => {
        console.log(res);
        this.tableData1.list = res.rows;
        this.tableData1.total1 = res.total;
      });
    },
    seach() {
      this.getCollectList(this.id, this.query);
    },
    handleCurrentChange1(val) {
      this.tableData1.currentpage = val;
      this.getCollectList();
    }
    // tianzhang() {
    //   let routeUrl = this.$router.resolve({
    //     path: "/TaiZhang",
    //     query: { id: 96 }
    //   });
    //   window.open(
    //     routeUrl.href,
    //     "newwindow",
    //     "height=800, width=1500, top=150, left=300, toolbar=no, menubar=no, scrollbars=yes, resizable=no, location=no, status=no"
    //   );
    // }
  }
};
</script>

<style lang="scss" scoped>
#details {
  padding-top: 90px;
}
.details-content {
  height: calc(100vh - 90px);
  overflow-y: auto;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
}
.flex {
  display: flex;
}
.flex-center {
  align-items: center;
}
.flex-v-center {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.return-img {
  width: 12.87vh;
  height: 4.444vh;
  margin-right: 20px;
  cursor: pointer;
}
.bgimg {
  background: url("../../assets/images/merchants_details/img_title.png")
    no-repeat;
  background-size: 100% 100%;
  display: inline-block;
  padding: 10px 20px;
}
.bg {
  background: rgba($color: #363b6e, $alpha: 0.29);
  border-radius: 5px;
  border: 1px solid #3d4283;
  overflow: hidden;
  margin-bottom: 20px;
}
.height280 {
  height: 25.926vh;
}
.certificate {
  flex: 1;
  margin-right: 10px;
  background: #030e24;
  border-radius: 5px;
  text-align: center;
  overflow: hidden;
  &:last-of-type {
    margin-right: 0;
  }
  .el-image {
    width: 100%;
    height: 124px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .image-slot {
    img {
      width: 100%;
      height: 100%;
      padding: 20px;
    }
  }
  .text {
    height: 36px;
    line-height: 36px;
  }
}
.padding20 {
  padding: 20px;
}
.btn-time {
  background: radial-gradient(#3c427d, #666ca7);
  border: 1px solid #666ca7;
  border-radius: 4px;
  padding: 4px 6px;
  font-size: 0.875rem;
  margin-right: 10px;
  cursor: pointer;
}
.btn-time:hover {
  color: #ccc;
  border: 1px solid rgba($color: #666ca7, $alpha: 0.8);
}
.btn-time-active {
  background: #191c3c;
  border: 1px solid #323877;
}
#chart-line {
  width: 100%;
  height: 300px;
}
#details-chart-bar {
  width: 100%;
  height: 240px;
}
#details-chart-bar2 {
  width: 100%;
  height: 240px;
}
.el-divider--vertical {
  height: 80%;
}
.empty {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #a0ccdf;
  img {
    margin-bottom: 30px;
  }
}
// 表格
table {
  border-spacing: 0;
  width: 100%;
  height: 100%;
}
td {
  border-top: 1px solid #3d4283;
  border-right: 1px solid #3d4283;
  border-bottom: 0;
  border-left: 0;

  padding-left: 0.926vh;
}
.b-r {
  border-right: 0;
}
.b-t {
  td {
    border-top: 0;
  }
}
.td-bg {
  text-align: center;
  background: #2e3367;
}
.tooltip {
  cursor: pointer;
}
.margin-b-20 {
  margin-bottom: 1.852vh;
}
.el-range-editor {
  margin: 0 20px;
}
.icon-img {
  width: 5.741vh;
  height: 5.741vh;
  margin-right: 20px;
}
.title-item {
  font-size: 1.25rem;
  color: #5de5f5;
  margin-bottom: 20px;
}
</style>
